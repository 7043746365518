
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

//importamos los comp creados
import Home from './components/Home';


function App() {
  return (
    <div className="App">

<BrowserRouter>
<Routes>
  <Route>
    <Route index element={ <Home/> } />
    <Route path='*' element={ <Navigate replace to="/"/> }/>
  </Route>
</Routes> 
</BrowserRouter>

    </div>
  );
}

export default App;