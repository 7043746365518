//Importaciones de Menu
import { Outlet, Link } from "react-router-dom"
import logo from '../images/logo.png'
//imagenes de carousel
import caru1 from '../images/car1.jpg'
import caru2 from '../images/car2.jpg'
import caru3 from '../images/car3.jpg'
// iMPORTACIONES DE TODO Boostrap
import { Navbar, Nav, Container, Button, Carousel, Row, Col, ListGroup, Form } from 'react-bootstrap';
//Importaciones de section 1
import secta from '../images/sect1.png';
import sectb from '../images/sect2.png';
import sectc from '../images/sect3.png';
import sectd from '../images/sect4.png';
import secte from '../images/sect5.png';
import sectf from '../images/sect6.png';
import sectg from '../images/sect7.png';
import secth from '../images/sect8.png';
//IMPORTACIONES DE SECTION2
import '../css/Home.css'; 
import emailjs from '@emailjs/browser';
//imagen de advertncia 
import Adv from '../images/adv.png'
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
// WHATSAPP BOTON
import WhatsAppFloatingButton from '../components/whatsapp';
//GOOGLE ANALYTICS
import ReactGA from 'react-ga';
// IMPORTACIONES DE SECTION3
import bandea from '../images/bande1.png';
import bandeb from '../images/bande2.png';
import bandec from '../images/bande3.png';
import banded from '../images/bande4.png';
// IMORTACIONES SECTION 2
import carta from '../images/cart1.png';
import cartb from '../images/cart2.png';
import cartc from '../images/cart3.png';
// IMPOR DE FOOTER
import { FaFacebook, FaPhone, FaWhatsapp } from 'react-icons/fa';

function Home() {
  //CODIGO PARA FORMULARIO FUNCIONE
  const handleSubmit = (e) => {
    e.preventDefault();

    // Aquí deberías manejar la lógica de envío del formulario usando EmailJS
    // Puedes obtener las credenciales de EmailJS y el ID del servicio desde su sitio web

    // Ejemplo de uso básico de EmailJS
    emailjs.sendForm('service_n3bqzpi', 'template_ljaf5bz', e.target, 'GhYoaFA6wL7Pj0beC')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });

    // Restablece el formulario después de enviar
    e.target.reset();
  };
  const handleLlamarClick = () => {
    // Lógica para llamar, por ejemplo, redirigir a una página de marcado de teléfono
    window.location.href = 'tel:+5530310828'; // Reemplaza con el número de teléfono deseado
  };

//GOOGLE ANALYTICS 

// Configurar Google Analytics
ReactGA.initialize('G-T77GVKSGYF');
// Opcional: Establecer un nombre de página predeterminado
ReactGA.set({ page: window.location.pathname });
ReactGA.pageview(window.location.pathname + window.location.search);

// Define opciones de estilo que pueden ser editadas/////////////////
  const styles = {
    title: {
      fontSize: '30px', // Tamaño del título
      color: '#10312B',   // Color del título
      margin: '20px 0', // Margen superior e inferior
      fontWeight: 'bold', // Peso de la fuente
    },
    subtitle: {
      fontSize: '30px', // Tamaño del subtítulo
      color: '#10312B',    // Color del subtítulo
      margin: '20px 0', // Margen superior e inferior
      fontWeight: 'bold', // Peso de la fuente
    },
  };
////////////////////////////////////////////////////////////////////
return ( 
  
<div className="Homee">
<div>
      {/* Otros componentes de tu aplicación */}
      <WhatsAppFloatingButton className="pulsating-button"/>
    </div>
  <section className='Menu'>
    <div>
              <Container>
              <Navbar collapseOnSelect expand="lg" className="bg-transparent respm" style={{ marginTop: '20px' }}>     
              <Navbar.Brand as={Link} to="/" ><Flip left><img alt="logo" src={logo} width="30%" height="auto" /></Flip></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto">
              </Nav>
              {/* Botón en la parte derecha */}
              <Flip right><Button variant="danger" onClick={handleLlamarClick} style={{ marginLeft: '10px',  borderRadius: '20px' }} className="d-sm-none d-md-block">
               Contactanos
               </Button></Flip>              
              </Navbar.Collapse>    
               </Navbar>
              <section>
              <Outlet>
              </Outlet>
              </section> 
              </Container>
              </div>
</section>
{/* /////////////////////////////////////////////////////////////////////// */}
<section className="Carousel">
  <div>
  <Carousel className="custom-carousel">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={caru1}
          alt="First slide"
        />
        <Carousel.Caption className="text-overlay">
          <h3>Creditos sin AVAL con minimo Requisitos</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={caru2}
          alt="Second slide"
        />
        <Carousel.Caption className="text-overlay">
          <h3>Solucion a tus Problemas de Imediato.</h3>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={caru3}
          alt="Third slide"
        />
        <Carousel.Caption className="text-overlay">
          <h3>De 10,000 mxn a 1 millos de pesos</h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </div>
</section>

{/* /////////////////////////////////////////////////////////////////////// */}

<section className='section1'>
<div>
<Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8} className="text-center">
        <Fade left><h1 style={styles.title}>Experiencia de préstamo simple y rápida</h1></Fade>
        <Fade top><h2 style={styles.subtitle}>Proporcionamos una experiencia de préstamo simple y rápida para ayudarlo/a a satisfacer sus necesidades urgentes de dinero. Nuestro proceso de solicitud es sencillo y claro, permitiéndole obtener el efectivo que necesita de manera fácil y rápida.</h2></Fade>
        </Col>
      </Row>

<Row>
    <div className='Titlesec'>
        <Col>
        <Fade><h1 className='TitleP'>Acerca de...</h1></Fade>
        <Fade><h2 className='SubtiP'>NUESTROS SERVICIOS</h2></Fade>
        </Col>
    </div>
      </Row>
<Row>
        <Col xs={12} md={6} lg={4} className="mb-3">
          <div className="icon-container">
          <Bounce top>
            <img src={secta} alt="Icono 1" className="icon" />
            <div>
            <h2 className="textt">PRESTAMOS IMEDIATOS</h2>
            </div>
          </Bounce>
          </div>
        </Col>

        <Col xs={12} md={6} lg={4} className="mb-3">
          <div className="icon-container">
          <Bounce top>
            <img src={sectb} alt="Icono 1" className="icon" />
            <div>
            <h2 className="textt">PRESTAMOS PERSONALES</h2>
            </div>
            </Bounce>
          </div>
        </Col>

        <Col xs={12} md={6} lg={4} className="mb-3">
          <div className="icon-container">
          <Bounce top>
            <img src={sectc} alt="Icono 1" className="icon" />
            <div>
            <h2 className="textt">PRESTAMOS AUTOMOTRIZ</h2>
            </div>
            </Bounce>
          </div>
        </Col>

        

      </Row>

      <Row>

        <Col xs={12} md={6} lg={4} className="mb-3">
          <div className="icon-container">
          <Bounce bottom>
            <img src={sectd} alt="Icono 1" className="icon" />
            <div>
            <h2 className="textt">PRESTAMOS SIN AVAL</h2>
           
            </div>
            </Bounce>
          </div>
        </Col>

        <Col xs={12} md={6} lg={4} className="mb-3">
          <div className="icon-container">
          <Bounce bottom>
            <img src={secte} alt="Icono 1" className="icon" />
            <div>
            <h2 className="textt">PRESTAMOS HIPOTECARIOS</h2>
            </div>
            </Bounce>
          </div>
        </Col>

        <Col xs={12} md={6} lg={4} className="mb-3">
          <div className="icon-container">
          <Bounce bottom>
            <img src={sectf} alt="Icono 1" className="icon" />
            <div>
            <h2 className="textt">PRESTAMOS BANCARIOS</h2>
            </div>
            </Bounce>
          </div>
        </Col>

        <Col xs={12} md={6} lg={4} className="mb-3">
          <div className="icon-container">
          <Bounce top>
            <img src={sectg} alt="Icono 1" className="icon" />
            <div>
            <h2 className="textt">CRÉDITO PYME</h2>
            </div>
            </Bounce>
          </div>
        </Col>

        <Col xs={12} md={6} lg={4} className="mb-3">
          <div className="icon-container">
          <Bounce top>
            <img src={secth} alt="Icono 1" className="icon" />
            <div>
            <h2 className="textt">PRESTAMOS EDUCATIVOS</h2>
            </div>
            </Bounce>
          </div>
        </Col>
      </Row>
    </Container>
    </div>
</section>

{/* /////////////////////////////////////////////////////////////////////// */}

<section className='section2 mt-5'>
<div className="background-section2">
<Container className="mt-5">
      <Row className="justify-content-center pb-5 pt-4">
        <Col md={8} className="text-center">
        <Fade left><h1 style={styles.title}>Opciones flexibles de pago</h1></Fade>
        <Fade><p className="ParfP mb-3">
        Para brindarle mayor flexibilidad, ofrecemos múltiples opciones de pago, para que pueda elegir la forma de pago que mejor se adapte a su situación. Nos comprometemos a ayudarlo/a a administrar fácilmente sus finanzas.</p></Fade>
        <Fade>
        <p className="ParfP">
        Nuestro equipo de atención al cliente está disponible para brindarle un servicio de alta calidad. Si tiene alguna pregunta, inquietud o necesita
        </p></Fade>
        </Col>
      </Row>

      <Row className="justify-content-center pb-5 pt-4">
        {/* Imagen 1 */}
        <Col xs={12} md={6} lg={3} className="mb-4">
        <Bounce top><img src={carta} alt="Imagen 1" className="img-fluid" /></Bounce>
        </Col>

        {/* Imagen 2 */}
        <Col xs={12} md={6} lg={3} className="mb-4">
        <Bounce bottom><img src={cartb} alt="Imagen 2" className="img-fluid" /></Bounce>
        </Col>

        {/* Imagen 3 */}
        <Col xs={12} md={6} lg={3} className="mb-4">
        <Bounce top><img src={cartc} alt="Imagen 3" className="img-fluid" /></Bounce>
        </Col>
      </Row>
    </Container>
</div>
</section>

{/* /////////////////////////////////////////////////////////////////////// */}

<section>
    <div className='section3'>
    <Container className="mt-5">
    <Row className="justify-content-center">
        <Col>
        <Fade left><h2 className='tituloEstilo'>CARACTERÍSTICAS DEL CRÉDITO</h2></Fade>
          <Fade><ListGroup variant="flush">
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>1. Recupera el control de tu capital y mejora tu liquidez.</ListGroup.Item>
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>2. Reduce el monto de tus cuotas en cada periodo</ListGroup.Item>
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>3. Pagos flexibles con frecuencias semanal, catorcenal, quincenal o mensual.</ListGroup.Item>
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>Oportunidad de realizar pagos adelantados sin penalizaciones ni cargos extra.</ListGroup.Item>
          </ListGroup></Fade>
          <Fade left><h2 className='tituloEstilo mp-5'style={{ marginTop: '20px', marginBottom: '60px'}}>¿QUÉ NECESITAS?</h2></Fade>
        </Col>
      </Row>

      <Row>
        <Col sm={6} className="centrarcolsec3">
        <Bounce top><img
            src={bandea}
            alt="Imagen 1"
            className="img-fluid"
            style={{ marginBottom: '10px' }}
          /></Bounce>
        </Col>
        <Col sm={6} className="centrarcolsec3">
        <Bounce bottom><img
            src={bandeb}
            alt="Imagen 2"
            className="img-fluid"
            style={{ marginBottom: '10px' }}
          /></Bounce>
        </Col>
        <Col sm={6} className="centrarcolsec3">
        <Bounce top><img
            src={bandec}
            alt="Imagen 3"
            className="img-fluid"
            style={{ marginTop: '10px' }}
          /></Bounce>
        </Col>
        <Col sm={6} className="centrarcolsec3">
        <Bounce top><img
            src={banded}
            alt="Imagen 3"
            className="img-fluid"
            style={{ marginTop: '10px' }}
          /></Bounce>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col>
        <Fade left><h2 className='tituloEstilo'style={{ marginTop: '20px'}}>Nuestros logros</h2></Fade>
        <Fade><ListGroup variant="flush">
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>+180,000 Clientes.</ListGroup.Item>
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>+137,000 Préstamos otorgados.</ListGroup.Item>
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>$5,196 Millones prestados.</ListGroup.Item>
            <ListGroup.Item style={{ border: 'none', padding: '0' }}>+40,000 Inversionistas.</ListGroup.Item>
          </ListGroup></Fade>
        </Col>
      </Row>
    </Container>
    </div>
</section>

{/* /////////////////////////////////////////////////////////////////////// */}

<section>
  <div className='section4'>
  <Container className="d-flex align-items-center justify-content-center min-vh-100 mt-5">
  <Form onSubmit={handleSubmit} className="form-inline formulario">

      <Row className="mb-3">
        <Form.Group as={Col} controlId="nombre">
          <Form.Label>Nombre</Form.Label>
          <Form.Control type="text" name="nombre" placeholder="Nombre" />
        </Form.Group>

        <Form.Group as={Col} controlId="apellido">
          <Form.Label>Apellido</Form.Label>
          <Form.Control type="text" name="apellido"  placeholder="Apellido" />
        </Form.Group>
      </Row>

      <Row className="mb-3">
      <Form.Group as={Col} className="mb-3" controlId="email">
        <Form.Label>Correo Electrónico</Form.Label>
        <Form.Control type="email" name="email" placeholder="Ingrese su correo electrónico" required />
      </Form.Group>

      <Form.Group as={Col} className="mb-3" controlId="telefono">
          <Form.Label>Teléfono</Form.Label>
          <Form.Control type="tel" name="telefono" placeholder="Ingrese su número de teléfono" required />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" controlId="montoCredito">
              <Form.Label>Monto de Crédito</Form.Label>
              <Form.Control as="select" name="montoCredito" required>
                <option value="">Seleccione el monto de crédito</option>
                <option value="1000">$1,000</option>
                <option value="5000">$5,000</option>
                <option value="10000">$10,000</option>
                <option value="10000">$1,000,000</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="tipoFinanciamiento">
              <Form.Label>Tipo de Financiamiento</Form.Label>
              <Form.Control as="select" name="tipoFinanciamiento" required>
                <option value="">Seleccione el Tipo de Financiamiento</option>
                <option value="Persona Fisica">Persona Física</option>
                <option value="Persona Moral">Persona Moral</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="comentarios">
              <Form.Label>Comentarios</Form.Label>
              <Form.Control as="textarea"  rows={3} name="comentarios" placeholder="Ingrese sus comentarios" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="aceptarTerminos">
              <Form.Check
                type="checkbox"
                name="aceptarTerminos"
                label="Aceptar términos y condiciones"
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              SOLICITAR AHORA
            </Button>
    </Form>
    </Container>
  </div>

  <section>
    <div className='section5'>
    <Container fluid className="d-flex align-items-center justify-content-center mt-5">
      <Row>
      <Col md={10} lg={8} xl={6} className="mx-auto text-center seccion">
      <div className="contenido">
          <img
            src={Adv}
            alt="Advertencia"
            className="img-fluid advertencia"
          />
          <Fade bottom big><p>
          En este tiempo hemos impactado a más de 170 mil personas en México a través de 129 mil préstamos que representan más de $4,900 millones de pesos prestados. Además contamos con una comunidad creciente de 36 mil personas que obtienen rendimientos por su dinero.
          </p></Fade>
          </div>

        </Col>
      </Row>
    </Container>
    </div>
  </section>
</section>


<footer className='footer mt-5'>
      <Container>
        <Row>
          <Col md={4}>
            <h4>Nuestra misión</h4>
            <p>Incentivar el crecimiento económico, nacional, regional y sectorial, mediante el fomento a la productividad e innovación de las micro, pequeñas y medianas empresas ubicadas en sectores estratégicas, que impulse el fortalecimiento ordenado, planificado y sistemático del emprendimiento y del desarrollo empresarial en todo el territorio nacional, así como la consolidación de una economía innovadora, dinámica y competitiva.</p>
          </Col>
          <Col md={4}>
          <Navbar bg="tranparent" variant="dark" className="icon-bar">
      <Nav className="mx-auto">
        <Nav.Link href="https://www.facebook.com/Serfimex.mx" className="icon-link">
          <FaFacebook size={30} />
        </Nav.Link>
        <Nav.Link className="icon-link" variant="danger" onClick={handleLlamarClick} >
          <FaPhone size={30} />
        </Nav.Link>
        <Nav.Link href="https://wa.link/54ethm" className="icon-link">
          <FaWhatsapp size={30} />
        </Nav.Link>
      </Nav>
    </Navbar>
          </Col>
          <Col md={4}>
            <h4>Consejo Directivo </h4>
            <p>Está conformado por emprendedoras y emprendedores que, de forma honoraria, dedican su tiempo a las actividades más estratégicas de la asociación. Se renueva cada tres años por votación de la Asamblea General. La Presidencia del Consejo Directivo es elegida de entre los integrantes del mismo Consejo Directivo.</p>
          </Col>
        </Row>
        <div className='text-center text-white p-3 ' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        Copyright © 2023 SERFIMEX. Todos los derechos reservados.

      </div>
      </Container>
    </footer>
</div>



 );
}

export default Home;
