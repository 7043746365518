
import whats from '../images/whats.jpg';
// WhatsAppFloatingButton.js
import React from 'react';
import '../css/whats.css';

const WhatsAppFloatingButton = () => {
  const phoneNumber = '5530310828'; // Reemplaza con tu número de teléfono

  const handleWhatsAppClick = () => {
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsappLink, '_blank');
  };

  return (
    <div className="whatsapp-floating-btn" onClick={handleWhatsAppClick}>
      <img
        src={whats} // Reemplaza con la ruta correcta de tu imagen
        alt="WhatsApp"
        className="whatsapp-icon"
      />
    </div>
  );
};

export default WhatsAppFloatingButton;

